$gen-cont-teaser-title-font-family            : $font-headline !default;
$gen-cont-teaser-title-color                  : color(base) !default;
$gen-cont-teaser-title-margin-bottom--mobile  : 20px !default;
$gen-cont-teaser-title-margin-bottom--desktop : 40px !default;
$gen-cont-teaser-title-font-size--mobile      : font-size(xxl-m) !default;
$gen-cont-teaser-title-font-size--desktop     : font-size(xxl) !default;
$gen-cont-teaser-text-font-family             : $font-base !default;
$gen-cont-teaser-text-font-color              : color(base) !default;
$gen-cont-teaser-text-margin-bottom--mobile   : spacing(m) !default;
$gen-cont-teaser-text-margin-bottom--desktop  : spacing(l) !default;
$gen-cont-teaser-text-line-height--mobile     : 22px !default;
$gen-cont-teaser-text-line-height--desktop    : 28px !default;
$gen-cont-teaser-text-font-size--mobile       : font-size(xs) !default;
$gen-cont-teaser-text-font-size--desktop      : font-size(m) !default;

.c-general-content-teaser {
    height      : 100%;
    display     : flex;
    align-items : center;

    a {
        text-decoration : none;
        width           : 100%;
    }

    .c-general-content-teaser__link {
        text-decoration : none;

        .c-general-content-teaser__content {
            padding-bottom : 30px;
            display        : flex;
            flex-direction : column;

            @media only screen and (min-width : 1025px) {
                padding-bottom : 0;
            }

            .c-general-content-teaser__title {
                font-family   : $font-headline;
                color         : $gen-cont-teaser-title-color;
                margin-bottom : $gen-cont-teaser-title-margin-bottom--mobile;
                font-size     : $gen-cont-teaser-title-font-size--mobile;
                text-align    : center;

                @media only screen and (min-width : 1025px) {
                    margin-bottom : $gen-cont-teaser-title-margin-bottom--desktop;
                    font-size     : $gen-cont-teaser-title-font-size--desktop;
                    text-align    : left;
                }
            }

            .c-general-content-teaser__text {
                margin-bottom : $gen-cont-teaser-text-margin-bottom--mobile;
                font-size     : $gen-cont-teaser-text-font-size--mobile;
                font-family   : $gen-cont-teaser-text-font-family;
                color         : $gen-cont-teaser-text-font-color;
                line-height   : $gen-cont-teaser-text-line-height--mobile;
                text-align    : center;

                @media only screen and (min-width : 1025px) {
                    margin-bottom : $gen-cont-teaser-text-margin-bottom--desktop;
                    font-size     : $gen-cont-teaser-text-font-size--desktop;
                    line-height   : $gen-cont-teaser-text-line-height--desktop;
                    text-align    : left;
                }
            }

            button {
                width  : fit-content;
                margin : 0 auto;

                @media only screen and (min-width : 1025px) {
                    margin : 0;
                }
            }
        }
    }
}
